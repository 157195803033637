import React, { useState } from "react";
import AboutUs from "./AboutUs";
import Information from "./Information";
import OurServices from "./OurServices";

const defaultLanguage = "se";
export const LanguageContext = React.createContext();

function Home() {
  const [language, setLanguage] = useState(() => {
    const langFromLocalStorage = window.localStorage.getItem("lang");

    return langFromLocalStorage ? langFromLocalStorage : defaultLanguage;
  });

  React.useEffect(() => {
    window.localStorage.setItem("lang", language);
  }, [language]);

  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      <title>Software Solutions-Home Page</title>
      <AboutUs />
      <Information />
      <OurServices />
    </LanguageContext.Provider>
  );
}

export default Home;
