import { React } from "react";
import "../CSS/main.css";
import "../CSS/contact.css";
import { useContext } from "react";
import { LanguageContext } from "../App";
import { scrollContact } from "../functions/scrollContact";
import Grid from "@mui/material/node/Grid";
import { Helmet } from "react-helmet";

function Contact() {
  const languages = {
    en: {
      contactUs: "Contact us for stylish solutions",
      idea: "Do you have an idea but don't know where to start?",
      need: "Do you need a website? ",
      solution: "Do you want a software solution that doesn't exist yet?",
      we: "We are here for you and will get back to you as soon as possible!",
      ourTel: "+46739765954",
      message: "Send a message",
      email: "email/phone",
      name: "name",
      website: "your website (optional)",
      question: "message",
      phone: "Phone",
      submit: "submit",
      tel: "+46739765954",
    },
    se: {
      contactUs: "Kontakta oss för snygga lösningar",
      idea: "Har ni en idé men vet inte var ni ska börja?",
      need: "Behöver ni en hemsida?",
      solution: "Vill ni ha en mjukvarulösning som inte finns ännu?",
      we: "Vi jobbar med kunder i hela Sverige, inte bara Stockholm.",
      vi: "Vi finns här för er och återkommer till er så snart som möjligt!",
      ourTel: "0739765954",
      message: "Skicka ett meddelande",
      email: "e-mail/telefon",
      name: "namn",
      website: "er webbsida (valfritt)",
      question: "meddelande",
      phone: "Tel",
      submit: "skicka in",
      tel: "0739765954",
    },
  };

  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <section onLoad={scrollContact}>
      <Helmet>
        <title>Ekorporation kontakt</title>
        <meta
          name="description"
          content="Kontakta oss på Ekorporation för fina lösningar. Vi tar hand om webbplatser, mjukvara och blir era personliga IT-konsulter."
        />
      </Helmet>
      <div className="containerLittle" id="contactUs">
        <h1 style={{ marginTop: "0%" }}> {languages[language].contactUs}</h1>
        <section className="contactBox">
          <h4 className="contactBoxText">
            {languages[language].need}
            <br />
            {languages[language].idea} <br />
            {languages[language].solution}
          </h4>
          <h4 className="contactBoxText">
            {languages[language].we}
            <br />
            {languages[language].vi}
          </h4>
          <Grid container>
            <Grid item xs={5}>
              {" "}
              <img
                className="round"
                src={require("../images/EkorporationCEO.jpg")}
              />
            </Grid>
            <Grid item xs={6}>
              <h4 className="contactBoxText">
                {languages[language].phone}:{" "}
                <a style={{ color: "white" }} href="tel:+46739765954">
                  {languages[language].tel}
                </a>
                <br />
                Email:{" "}
                <a
                  style={{ color: "white" }}
                  href="mailto:mikael@ekorporation.se"
                >
                  {" "}
                  mikael@ekorporation.se{" "}
                </a>
                <br />
                Post:{" "}
                <a style={{ color: "white" }}>
                  Ekehjelmstorget 4, 11854 Stockholm
                </a>
              </h4>
            </Grid>
          </Grid>
        </section>
        <div className="contactForm" id="form">
          <form
            className="contactForm-content"
            action="https://formspree.io/f/myyqbpjg"
            method="post"
          >
            <h3 className="sendMessage">{languages[language].message}</h3>
            <br />
            <input
              className="inputContact"
              required
              placeholder={languages[language].email}
              name="contact"
            />
            <br />
            <input
              className="inputContact"
              required
              placeholder={languages[language].name}
              name="name"
            />
            <br />
            <input
              className="inputContact"
              placeholder={languages[language].website}
              name="web"
            />
            <br />
            <textarea
              className="inputContact inputQuestionContact"
              rows="5"
              cols="33"
              style={{ overflow: "hidden", resize: "none" }}
              placeholder={languages[language].question}
              name="question"
            />
            <br />
            <button className="submitContact" type="submit">
              {languages[language].submit}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
export default Contact;
