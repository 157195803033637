import { React, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../CSS/WhyUs.css";
import "../CSS/main.css";
import { LanguageContext } from "../App";
import Grid from "@mui/material/node/Grid";
import "../CSS/services.css";
import { scrollAboutUs } from "../functions/scrollAboutUs";
import { Helmet } from "react-helmet";

function WhyUs() {
  const languages = {
    en: {
      work: "How we work?",
      why: "Why hire us?",
      aboutHeader: "Ekorporation Digital Agency - we solve everything",
      ourClients: "Our Clients",
      aboutText:
        "We will take care of all  your technical requirements and let you focus on other important aspects of your business.",
      firstText:
        "Unlike many other web development companies, our team at Ekorporation has real coding expertise, allowing us to offer 100% customized and unique solutions for all your software needs. Whether it is crafting your webpage or developing any other software, we have the capabilities to create tailor-made solutions. We do not need to rely on availability of specific plugins in CMS platforms like WordPress; because we can develop the required functionality ourselves. This approach not only ensures a higher level of customization but also helps to cut costs in the long run, as many CMS plugins come with recurring billing. Our passion lies in exceeding your expectations, and we are committed to providing maximum transparency throughout the development process, including regular demos if you wish.",
      secondText:
        "Customer satisfaction is at the core of our values, and we go the extra mile to ensure that our solutions not only meet but exceed your expectations. Throughout the development process, we remain open to incorporating new requirements on the fly, ensuring the final product perfectly aligns with your needs. To help you make the most of the delivered solution, we provide free assistance in familiarizing yourself with the website's content management or offer training on the custom-made solution. Our commitment to your success doesn't end upon delivery; we offer ongoing support, monitoring, and improvements as needed. Prior to delivery, we conduct extensive testing to ensure that the solution is as robust and reliable as possible",
      OurServices: "Our Services",
      WebDevelopment: "Web Development",
      WebDevelopmentCreating: "◈ Creating a perfect website for your business",
      WebDevelopmentUpgrading: "◈ Upgrading your existing one",
      WebDevelopmentHosting: "◈ Hosting",
      WebDevelopmentEcom: "◈ E-commerce development",
      WebDevelopmentCoding: "◈ Coding from scratch",
      WebDevelopmentShopify: "◈ Wordpress, Shopify & other CMS",
      SEO: "SEO&Marketing",
      SEOOptimising: "◈ Optimising for Search Engines like Google",
      SEOManaging: "◈ Managing Social Media presence",
      SEOMarketing: "◈ Marketing Analytics",
      SEOData: "◈ Data driven solutions",
      Software: "Software Solutions",
      SoftwareBringing: "◈ Bringing your ideas to life",
      SoftwareFunctionality: "◈ Any functionality",
      SoftwareComplexity: "◈ Any complexity",
      SoftwareIntegrations: "◈ Any integrations",
      SoftwareEverything: "◈ Everything is possible",
      see: "See All",
      link: "More",
      src: require("../images/HowWeWorkEn.png"),
    },
    se: {
      work: "Vår process",
      why: "Varför anlita oss?",
      aboutHeader: "Ekorporation Digitalbyrå - vi löser allt!",
      ourClients: "Våra Kunder ",
      aboutText:
        "Vi tar hand om alla era tekniska krav och låter er fokusera på andra viktiga aspekter av er företag.",
      firstText:
        "Till skillnad från många andra webbutvecklingsföretag har vårt team på Ekorporation verklig kodningskompetens, vilket gör det möjligt för oss att erbjuda 100% anpassade och unika lösningar för alla era programvarubehov. Oavsett om det handlar om att designa er webbsida eller utveckla en annan programvara har vi förmågan att skapa skräddarsydda lösningar. Vi behöver inte förlita oss på tillgängligheten av specifika tillägg i CMS-plattformar som WordPress; eftersom vi kan utveckla den nödvändiga funktionaliteten själva. Denna metod säkerställer inte bara en högre grad av anpassning, utan hjälper även till att minska kostnaderna på lång sikt, då många CMS-tillägg har återkommande kostnader. Vår passion ligger i att överträffa dina förväntningar, och vi är engagerade i att erbjuda maximal transparens under hela utvecklingsprocessen, inklusive regelbundna demo sessioner om ni önskar dem.",
      secondText:
        "Kundnöjdhet ligger i hjärtat av våra värderingar, och vi går den extra milen för att säkerställa att våra lösningar inte bara uppfyller utan överträffar era förväntningar. Under hela utvecklingsprocessen är vi öppna för att inkludera nya krav i realtid, vilket hjälper den slutliga produkten att vara perfekt anpassad efter era behov. För att hjälpa er att dra full nytta av den levererade lösningen erbjuder vi gratis hjälp med att lära känna webbplatsens innehållshantering eller erbjuder vi träning på den skräddarsydda lösningen. Vårt åtagande för din framgång avslutas inte vid leverans; vi erbjuder kontinuerlig support, övervakning och förbättringar vid behov. Innan leveransen genomför vi omfattande tester för att säkerställa att lösningen är så robust och pålitlig som möjligt.",
      OurServices: "Våra Tjänster",
      WebDevelopment: "Webbutveckling",
      WebDevelopmentCreating:
        "◈ Skapande av en perfekt webbplats för er företag",
      WebDevelopmentUpgrading: "◈ Uppgradering av er befintliga webbplats",
      WebDevelopmentHosting: "◈ Webbhotell tjänster",
      WebDevelopmentEcom: "◈ E-handelsutvecklingt",
      WebDevelopmentCoding: "◈ Kodning från grunden",
      WebDevelopmentShopify: "◈ WordPress, Shopify och andra CMS-plattformar",
      SEO: "SEO&Marknadsföring",
      SEOOptimising: "◈ Optimering för sökmotorer som Google",
      SEOManaging: "◈ Hantering av närvaro på sociala medier",
      SEOMarketing: "◈ Marknadsföringsanalys",
      SEOData: "◈ Datadrivna lösningar",
      Software: "Skräddarsydda programvarulösningar",
      SoftwareBringing: "◈ Gör era idéer till verklighet",
      SoftwareFunctionality: "◈ All funktionalitet",
      SoftwareComplexity: "◈ All komplexitet",
      SoftwareIntegrations: "◈ Alla integrationer",
      SoftwareEverything: "◈ Allt är möjligt",
      see: "Se Alla",
      link: "Mer",
      src: require("../images/HowWeWorkSWE.png"),
    },
  };

  const [language, setLanguage] = useContext(LanguageContext);
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/services");
  };
  return (
    <section id="aboutUs" onLoad={scrollAboutUs}>
      <Helmet>
        <title>Ekorporation din digital byrå i Sverige</title>
        <meta
          name="description"
          content="Ekorporation är en digital byrå som kan ta hand om alla era IT-relaterade frågor (webbplatser, mjukvara, UI/UX, SEO, automatisering m.m.) med den högsta kvalité och maximal transparens."
        />
      </Helmet>
      <div className="aboutBox">
        <img
          className="aboutPicture"
          src={require("../images/newWave.png")}
          alt="about us"
        />
        <div className="whyUsText">
          <h1 style={{ marginTop: "0%" }}>{languages[language].aboutHeader}</h1>
          <p className="whyText">{languages[language].aboutText}</p>
        </div>
      </div>
      <div className="ourClientsText">
        <h3 className="bigHeader">{languages[language].ourClients}</h3>
      
      </div>
      <h3 className="whyWe whyHireUs bigHeader">{languages[language].why}</h3>
      <Grid container className="aboutContainer">
        <Grid item xs={12} md={6}>
          <article className="pText">{languages[language].firstText}</article>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            className="digital"
            src={require("../images/digitalFirst.png")}
            alt="digital"
          />
        </Grid>
        <Grid className="hiddenGrid" item xs={12} md={6}>
          <article className="pText">{languages[language].secondText}</article>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            className="digital"
            src={require("../images/digitalSecond.png")}
            alt="digital"
          />
        </Grid>
        <Grid className="visibleGrid" item xs={12} md={6}>
          <article className="pText">{languages[language].secondText}</article>
        </Grid>
      </Grid>
      <div className="whyWe">
        <h3 className="how bigHeader">{languages[language].work}</h3>
        <img
          className="working"
          src={languages[language].src}
          alt="working process"
        />
      </div>
      <div className="services">
        <h3 className="bigHeader">{languages[language].OurServices}</h3>
      </div>
      <Grid container className="servicesContainer">
        <Grid item xs={12} sm={4}>
          <article className="rectangle">
            <img
              className="squirrel"
              src={require("../images/web.png")}
              alt="web development"
            />
            <h4 className="header">{languages[language].WebDevelopment}</h4>
            <h2 className="infoHover">
              {languages[language].WebDevelopmentCreating}
            </h2>
            <h2 className="infoHover">
              {languages[language].WebDevelopmentUpgrading}
            </h2>
            <h2 className="infoHover">
              {languages[language].WebDevelopmentHosting}
            </h2>
            <h2 className="infoHover">
              {languages[language].WebDevelopmentEcom}
            </h2>
            <h2 className="infoHover">
              {languages[language].WebDevelopmentCoding}
            </h2>
            <h2 className="infoHover">
              {languages[language].WebDevelopmentShopify}
            </h2>
            <div className="link" id="linkWeb">
              <a onClick={navigateHome}>
                {languages[language].link} {">"}{" "}
              </a>
            </div>
          </article>
        </Grid>
        <Grid item xs={12} sm={4}>
          <article className="rectangle">
            <img
              className="squirrel"
              src={require("../images/seoService.png")}
              alt="SEO and marketing"
            />
            <h4 className="header">{languages[language].SEO}</h4>
            <h2 className="infoHover">{languages[language].SEOOptimising}</h2>
            <h2 className="infoHover">{languages[language].SEOManaging}</h2>
            <h2 className="infoHover">{languages[language].SEOMarketing}</h2>
            <h2 className="infoHover">{languages[language].SEOData}</h2>
            <div className="link" id="linkSEO">
              <a onClick={navigateHome}>
                {languages[language].link}
                {">"}{" "}
              </a>
            </div>
          </article>
        </Grid>
        <Grid item xs={12} sm={4}>
          <article className="rectangle">
            <img
              className="squirrel"
              src={require("../images/custom.png")}
              alt="Software Solutions"
            />
            <h4 className="header">{languages[language].Software}</h4>
            <h2 className="infoHover">
              {languages[language].SoftwareBringing}
            </h2>
            <h2 className="infoHover">
              {languages[language].SoftwareFunctionality}
            </h2>
            <h2 className="infoHover">
              {languages[language].SoftwareComplexity}
            </h2>
            <h2 className="infoHover">
              {languages[language].SoftwareIntegrations}
            </h2>
            <h2 className="infoHover">
              {languages[language].SoftwareEverything}
            </h2>{" "}
            <div className="link" id="linkSofrware">
              <a onClick={navigateHome}>
                {languages[language].link} {">"}{" "}
              </a>
            </div>
          </article>
        </Grid>
      </Grid>
      <div className="services">
        <div className="seeHexagon" onClick={navigateHome}>
          <div className="littleSeeHexagon">
            <h4>{languages[language].see}</h4>
          </div>
        </div>
      </div>
    </section>
  );
}
export default WhyUs;
