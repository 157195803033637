import React, { useState } from "react";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import WhyUs from "./components/WhyUs";
import Home from "./components/Home";
import Prices from "./components/Prices";
import Contact from "./components/Contact";
import Services from "./components/Services";

const defaultLanguage = "se";
export const LanguageContext = React.createContext();

function App() {
  const [language, setLanguage] = useState(() => {
    const langFromLocalStorage = window.localStorage.getItem("lang");

    return langFromLocalStorage ? langFromLocalStorage : defaultLanguage;
  });

  React.useEffect(() => {
    window.localStorage.setItem("lang", language);
  }, [language]);

  return (
    <BrowserRouter>
      <LanguageContext.Provider value={[language, setLanguage]}>
        <Menu />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route path="/aboutUs" element={<WhyUs />}></Route>
          <Route path="/prices" element={<Prices />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </LanguageContext.Provider>
    </BrowserRouter>
  );
}

export default App;
