import { React, useContext } from "react";
import "../CSS/main.css";
import "../CSS/services.css";
import { LanguageContext } from "../App";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

function OurServices() {
  const languages = {
    en: {
      OurServices: "Our Services",
      WebDevelopment: "Web Development",
      WebDevelopmentCreating: "◈ Creating a perfect website for your business",
      WebDevelopmentUpgrading: "◈ Upgrading your existing one",
      WebDevelopmentHosting: "◈ Hosting",
      WebDevelopmentEcom: "◈ E-commerce development",
      WebDevelopmentCoding: "◈ Coding from scratch",
      WebDevelopmentShopify: "◈ Wordpress, Shopify & other CMS",
      SEO: "SEO&Marketing",
      SEOOptimising: "◈ Optimising for Search Engines like Google",
      SEOManaging: "◈ Managing Social Media presence",
      SEOMarketing: "◈ Marketing Analytics",
      SEOData: "◈ Data driven solutions",
      Software: "Software Solutions",
      SoftwareBringing: "◈ Bringing your ideas to life",
      SoftwareFunctionality: "◈ Any functionality",
      SoftwareComplexity: "◈ Any complexity",
      SoftwareIntegrations: "◈ Any integrations",
      SoftwareEverything: "◈ Everything is possible",
      see: "See All",
      link: "More",
    },
    se: {
      OurServices: "Våra Tjänster",
      WebDevelopment: "Webbutveckling",
      WebDevelopmentCreating: "◈ Skapande av en perfekt webbplats för er företag",
      WebDevelopmentUpgrading: "◈ Uppgradering av er befintliga webbplats",
      WebDevelopmentHosting: "◈ Webbhotell tjänster",
      WebDevelopmentEcom: "◈ E-handelsutvecklingt",
      WebDevelopmentCoding: "◈ Kodning från grunden",
      WebDevelopmentShopify: "◈ WordPress, Shopify och andra CMS-plattformar",
      SEO: "SEO&Marknadsföring",
      SEOOptimising: "◈ Optimering för sökmotorer som Google",
      SEOManaging: "◈ Hantering av närvaro på sociala medier",
      SEOMarketing: "◈ Marknadsföringsanalys",
      SEOData: "◈ Datadrivna lösningar",
      Software: "Skräddarsydda programvarulösningar",
      SoftwareBringing: "◈ Gör era idéer till verklighet",
      SoftwareFunctionality: "◈ All funktionalitet",
      SoftwareComplexity: "◈ All komplexitet",
      SoftwareIntegrations: "◈ Alla integrationer",
      SoftwareEverything: "◈ Allt är möjligt",
      see: "Se Alla",
      link: "Mer",
    },
  };

  const [language, setLanguage] = useContext(LanguageContext);
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/services");
  };
  return (
    <section>
      <div className="services">
        <h3 className="bigHeader">{languages[language].OurServices}</h3>
      </div>
      <Grid container className="servicesContainer">
        <Grid item xs={12} sm={4}>
          <article className="rectangle">
            <img
              className="squirrel"
              src={require("../images/web.png")}
              alt="web development"
            />
            <h4 className="header">{languages[language].WebDevelopment}</h4>
            <h2 className="infoHover">
              {languages[language].WebDevelopmentCreating}
            </h2>
            <h2 className="infoHover">
              {languages[language].WebDevelopmentUpgrading}
            </h2>
            <h2 className="infoHover">
              {languages[language].WebDevelopmentHosting}
            </h2>
            <h2 className="infoHover">
              {languages[language].WebDevelopmentEcom}
            </h2>
            <h2 className="infoHover">
              {languages[language].WebDevelopmentCoding}
            </h2>
            <h2 className="infoHover">
              {languages[language].WebDevelopmentShopify}
            </h2>
            <div className="link" id="linkWeb">
              <a onClick={navigateHome}>
                {languages[language].link} {" >"}{" "}
              </a>
            </div>
          </article>
        </Grid>
        <Grid item xs={12} sm={4}>
          <article className="rectangle">
            <img
              className="squirrel"
              src={require("../images/seoService.png")}
              alt="SEO and marketing"
            />
            <h4 className="header">{languages[language].SEO}</h4>
            <h2 className="infoHover">{languages[language].SEOOptimising}</h2>
            <h2 className="infoHover">{languages[language].SEOManaging}</h2>
            <h2 className="infoHover">{languages[language].SEOMarketing}</h2>
            <h2 className="infoHover">{languages[language].SEOData}</h2>
            <div className="link" id="linkSEO">
              <a onClick={navigateHome}>
                {languages[language].link}
                {" >"}{" "}
              </a>
            </div>
          </article>
        </Grid>
        <Grid item xs={12} sm={4}>
          <article className="rectangle">
            <img
              className="squirrel"
              src={require("../images/custom.png")}
              alt="Software Solutions"
            />
            <h4 className="header">{languages[language].Software}</h4>
            <h2 className="infoHover">
              {languages[language].SoftwareBringing}
            </h2>
            <h2 className="infoHover">
              {languages[language].SoftwareFunctionality}
            </h2>
            <h2 className="infoHover">
              {languages[language].SoftwareComplexity}
            </h2>
            <h2 className="infoHover">
              {languages[language].SoftwareIntegrations}
            </h2>
            <h2 className="infoHover">
              {languages[language].SoftwareEverything}
            </h2>
            <div className="link" id="Software">
              <a onClick={navigateHome}>
                {languages[language].link} {" >"}{" "}
              </a>
            </div>
          </article>
        </Grid>
      </Grid>

      <div className="services">
        <div className="seeHexagon" onClick={navigateHome}>
          <div className="littleSeeHexagon">
            <h4>{languages[language].see}</h4>
          </div>
        </div>
      </div>
    </section>
  );
}
export default OurServices;
