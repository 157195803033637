import { React, useContext, useState, useEffect } from "react";
import { LanguageContext } from "../App";
import "../CSS/main.css";
import { scrollServices } from "../functions/scrollServices";
import { Helmet } from "react-helmet";

function Services() {
  const [openWeb, setOpenWeb] = useState(true);
  useEffect(() => {
    let handlerWeb = () => {
      setOpenWeb(false);
    };
    document.addEventListener("mouseup", handlerWeb);
    if (openWeb === true) {
      document.getElementById("web").style.backgroundColor = "var(--gray)";
    } else {
      document.getElementById("web").style.backgroundColor =
        "var(--background)";
    }
  });
  const [openIntegrations, setOpenIntegrations] = useState(false);
  useEffect(() => {
    let handlerIntegrations = () => {
      setOpenIntegrations(false);
    };
    document.addEventListener("mouseup", handlerIntegrations);
    if (openIntegrations === true) {
      document.getElementById("integrations").style.backgroundColor =
        "var(--gray)";
    } else {
      document.getElementById("integrations").style.backgroundColor =
        "var(--background)";
    }
  });
  const [openCustom, setOpenCustom] = useState(false);
  useEffect(() => {
    let handlerCustom = () => {
      setOpenCustom(false);
    };
    document.addEventListener("mouseup", handlerCustom);
    if (openCustom === true) {
      document.getElementById("custom").style.backgroundColor = "var(--gray)";
    } else {
      document.getElementById("custom").style.backgroundColor =
        "var(--background)";
    }
  });
  const [openSupport, setOpenSupport] = useState(false);
  useEffect(() => {
    let handlerSupport = () => {
      setOpenSupport(false);
    };
    document.addEventListener("mouseup", handlerSupport);
    if (openSupport === true) {
      document.getElementById("support").style.backgroundColor = "var(--gray)";
    } else {
      document.getElementById("support").style.backgroundColor =
        "var(--background)";
    }
  });

  const [openDesign, setOpenDesign] = useState(false);
  useEffect(() => {
    let handlerDesign = () => {
      setOpenDesign(false);
    };
    document.addEventListener("mouseup", handlerDesign);
    if (openDesign === true) {
      document.getElementById("design").style.backgroundColor = "var(--gray)";
    } else {
      document.getElementById("design").style.backgroundColor =
        "var(--background)";
    }
  });
  const [openSEO, setOpenSEO] = useState(false);
  useEffect(() => {
    let handlerSEO = () => {
      setOpenSEO(false);
    };
    document.addEventListener("mouseup", handlerSEO);
    if (openSEO === true) {
      document.getElementById("SEO").style.backgroundColor = "var(--gray)";
    } else {
      document.getElementById("SEO").style.backgroundColor =
        "var(--background)";
    }
  });
  const [openTest, setOpenTest] = useState(false);
  useEffect(() => {
    let handlerTest = () => {
      setOpenTest(false);
    };
    document.addEventListener("mouseup", handlerTest);
    if (openTest === true) {
      document.getElementById("test").style.backgroundColor = "var(--gray)";
    } else {
      document.getElementById("test").style.backgroundColor =
        "var(--background)";
    }
  });
  const [openHosting, setOpenHosting] = useState(false);
  useEffect(() => {
    let handlerHosting = () => {
      setOpenHosting(false);
    };
    document.addEventListener("mouseup", handlerHosting);
    if (openHosting === true) {
      document.getElementById("hosting").style.backgroundColor = "var(--gray)";
    } else {
      document.getElementById("hosting").style.backgroundColor =
        "var(--background)";
    }
  });
  const languages = {
    en: {
      webDevelopment: "Web Development",
      webText:
        "We provide comprehensive web development services to create a perfect website for your business or upgrade your existing one, regardless of complexity. Coding from scratch or building a website in a CMS like WordPress or Shopify, mobife friendly, robust and secure. Beyond development, we take care of hosting and offer ongoing support to guarantee your website's seamless performance.",
      integrations: "Integrations",
      integrationsText:
        "We specialize in seamlessly integrating various functionalities into your website or custom-made software solution. Our integration capabilities cover a wide range, from weather information services and food delivery platforms to essential features like payments, marketing analytics, and inventory management.  We can even power your website with cutting-edge AI technologies for enhanced performance and user experience.",
      custom: "Custom made solutions",
      customText:
        "If you have ever wished for more functionality in your current software solutions, or perhaps wanted to have one application instead of several, or thought of something that is not there, or maybe just wanted to automate that boring manual routine you have - we got you covered. We can make your wishes come to life!",
      support: "Technical support",
      supportText:
        "We provide unparalleled technical support to ensure your business runs smoothly. Our dedicated team of experts is available to assist you with any technical issues, offering prompt and efficient solutions and guidance. From troubleshooting to software upgrades, let us handle your technical needs.",
      design: "Design & Web design",
      designText:
        "Is your website or logo in need of a fresh, captivating look? Look no further! Our expert team will craft a stunning website design, create a unique logo for your project, and elevate your website, business cards, or merchandise with engaging photos and custom-made images. Let us breathe new life into your brand and leave a lasting impression on your audience.",
      SEO: "Search engine optimization",
      SEOText:
        "Our Search Engine Optimisation and Social Media Marketing services optimize your online presence for higher search rankings and increased organic traffic. With a data-driven and transparent approach, we analyze and refine strategies to deliver measurable results and ensure your business stays ahead of the competition.",
      test: "Test & Bugfix",
      testText:
        "Want to ensure your website is fast, mobile-friendly, and performs flawlessly for all your customers? Our expert team conducts rigorous testing to identify and resolve any issues, ensuring optimal user experience. Whether you need speed optimization or assistance with fixing bugs, we are here to support you every step of the way.",
      hosting: "Hosting",
      hostingText:
        "Our hosting services ensure your website stays online 24/7, offering fast loading times and secure data management. Trust in our robust infrastructure to support your online presence, while our expert team handles all technical aspects. Focus on your business, and we will take care of your hosting needs.",
      ourServices: "Our Services",
    },
    se: {
      webDevelopment: "Webbutveckling",
      webText:
        "Vi erbjuder omfattande webbutvecklingstjänster för att skapa en perfekt webbplats för er företag eller uppgradera er befintliga webbsidan, oavsett komplexitet. Vi kodar från grunden eller bygger en webbplats i en CMS som WordPress eller Shopify, mobilvänlig, robust och säker såklart. Utöver utveckling tar vi hand om webbhotell och erbjuder kontinuerlig support för att garantera er webbplats smidiga prestanda.",
      integrations: "Integrationer",
      integrationsText:
        "Vi är proffs när det kommer till integartioner. Vi kan enkelt integrera olika funktioner i er webbplats eller skräddarsydd programvarulösning. Våra integrationsmöjligheter täcker ett brett spektrum, från väderinformationstjänster och matleveransplattformar till väsentliga funktioner som betalningar, marknadsföringsanalys och lagerhantering. Vi kan till och med utrusta er webbplats med toppmoderna AI-teknologier för att förbättra prestanda och användarupplevelse.",
      custom: "Skräddarsydda lösningar",
      customText:
        "Om ni någonsin har önskat mer funktionalitet i era nuvarande programvara, eller velat ha allt i ett programm istället för flera olika. Om ni har kommit på något som inte än finns, eller möjligtvis ville ni bara automatisera den tråkiga manuella rutinen ni brukar göra- vi är här för er. Vi kan förverkliga era drömmar!",
      support: "Teknisk support",
      supportText:
        "Vi erbjuder enastående teknisk support för att se till att er företag fungerar smidigt. Vårt dedikerade team av experter är tillgängligt för att hjälpa er med eventuella tekniska problem, med snabba och effektiva lösningar och vägledning. Från felsökning till programuppdateringar, låt oss hantera era tekniska behov.",
      design: "Design & Webbdesign",
      designText:
        "Behöver er webbplats eller logotyp en ny, fängslande look? Letar ni inte längre! Vårt expertteam skapar en fantastisk webbplatsdesign, en unik logotyp för er projekt och tar er webbplats, visitkort eller merchandise till ett högre nivå med engagerande photon och skräddarsydda bilder. Låt oss  blåsa liv i er varumärke och lämna ett varaktigt intryck på er publik.",
      SEO: "SEO & SMM",
      SEOText:
        "Våra tjänster inom Sökmotoroptimering (SEO) och Social Media Marketing (SMM) optimerar er online-närvaro för att nå högre sökresultat och ökad organisk trafik. Med en datadriven och transparent tillvägagångssätt analyserar och förfinar vi strategier för att leverera mätbara resultat och se till att er företag håller sig före konkurrensen.",
      test: "Test & Bugfix",
      testText:
        "Vill ni försäkra er om att er webbplats är snabb, mobilvänlig och fungerar felfritt för alla era kunder? Vårt expertteam genomför omfattande tester för att identifiera och lösa eventuella problem, ",
      hosting: "Webbhotell",
      hostingText:
        "Våra webbhotellstjänster ser till att er webbplats är online 24/7 och erbjuder snabba laddningstider och säker hantering av data. Lita på vår robusta infrastruktur för att stödja er online-närvaro, medan vårt expertteam hanterar alla tekniska aspekter. Fokusera på er företag, så tar vi hand om era webbhotellbehov.",
      ourServices: "Våra Tjänster",
    },
  };

  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <div className="containerLittle" id="services" onLoad={scrollServices}>
      <Helmet>
        <title>Ekorporation webbsidor och digitala lösningar</title>
        <meta
          name="description"
          content="Ekorporation har bredd spectrum av tjänster: webbutveckling, programmering, WordPress, Shopify, Wix, SEO, UI/UX, digital design, Testning och bug fix, webbhotell, automatisering och teknisk support."
        />
      </Helmet>
      <div>
        <h3 className="bigHeader">{languages[language].ourServices}</h3>
      </div>
      <article className="line">
        <div
          id="web"
          className="service"
          onClick={() => {
            setOpenWeb(!openWeb);
          }}
        >
          <h4>{languages[language].webDevelopment}</h4>
          <p id="webText">{languages[language].webText}</p>
        </div>
        <div
          id="integrations"
          className="service"
          onClick={() => {
            setOpenIntegrations(!openIntegrations);
          }}
        >
          <h4>{languages[language].integrations}</h4>
          <p id="integrationsText">{languages[language].integrationsText}</p>
        </div>

        <div
          id="custom"
          className="service"
          onClick={() => {
            setOpenCustom(!openCustom);
          }}
        >
          <h4>{languages[language].custom}</h4>
          <p id="customText">{languages[language].customText}</p>
        </div>
        <div
          id="support"
          className="service"
          onClick={() => {
            setOpenSupport(!openSupport);
          }}
        >
          <h4>{languages[language].support}</h4>
          <p id="supportText">{languages[language].supportText}</p>
        </div>
      </article>
      <article className="line">
        <div
          className={`bigServiceWeb ${openWeb ? "show" : "hide"}`}
          id="webDevelopment"
        >
          <div className="centered">
            <p className="textAround textRight">
              {languages[language].webText}
            </p>
            <img
              className="serviceImageBig imageLeft"
              src={require("../images/web.png")}
              alt="webDevelopment"
            />
          </div>
        </div>
      </article>
      <article className="line">
        <div
          className={`bigServiceIntegrations ${
            openIntegrations ? "show" : "hide"
          }`}
        >
          <div className="centered">
            <p className="textAround textLeft">
              {" "}
              {languages[language].integrationsText}
            </p>
            <img
              className="serviceImageBig imageRight"
              src={require("../images/integration.png")}
              alt="integrations"
            />
          </div>
        </div>
      </article>
      <article className="line">
        <div className={`bigServiceCustom ${openCustom ? "show" : "hide"}`}>
          <div className="centered">
            <p className="textAround textRight">
              {languages[language].customText}
            </p>
            <img
              className="serviceImageBig imageLeft"
              src={require("../images/custom.png")}
              alt="custom"
            />
          </div>
        </div>
      </article>
      <article className="line">
        <div className={`bigServiceSupport ${openSupport ? "show" : "hide"}`}>
          <div className="centered">
            <p className="textAround textLeft">
              {languages[language].supportText}
            </p>
            <img
              className="serviceImageBig imageRight"
              src={require("../images/support.png")}
              alt="technical support"
            />
          </div>
        </div>
      </article>
      <article className="line">
        <div className={`bigServiceDesign ${openDesign ? "show" : "hide"}`}>
          <div className="centered">
            <p className="textAround textRight">
              {languages[language].designText}
            </p>
            <img
              className="serviceImageBig imageLeft"
              src={require("../images/design.png")}
              alt="design"
            />
          </div>
        </div>
      </article>
      <article className="line">
        <div className={`bigServiceSEO ${openSEO ? "show" : "hide"}`}>
          <div className="centered">
            <p className="textAround textLeft">{languages[language].SEOText}</p>
            <img
              className="serviceImageBig imageRight"
              src={require("../images/seoService.png")}
              alt="seo"
            />
          </div>
        </div>
      </article>
      <article className="line">
        <div className={`bigServiceTest ${openTest ? "show" : "hide"}`}>
          <div className="centered">
            <p className="textAround textRight">
              {languages[language].testText}
            </p>
            <img
              className="serviceImageBig imageLeft"
              src={require("../images/test.png")}
              alt="test"
            />
          </div>
        </div>
      </article>
      <article className="line">
        <div className={`bigServiceHosting ${openHosting ? "show" : "hide"}`}>
          <div className="centered">
            <p className="textAround textLeft">
              {languages[language].hostingText}
            </p>
            <img
              className="serviceImageBig imageRight"
              src={require("../images/hosting.png")}
              alt="hosting"
            />
          </div>
        </div>
      </article>

      <article className="line">
        <div
          id="design"
          className="service"
          onClick={() => {
            setOpenDesign(!openDesign);
          }}
        >
          <h4>{languages[language].design}</h4>
          <p id="designText">{languages[language].designText}</p>
        </div>
        <div
          className="service"
          id="SEO"
          onClick={() => {
            setOpenSEO(!openSEO);
          }}
        >
          <h4>{languages[language].SEO}</h4>
          <p id="seoText">{languages[language].SEOText}</p>
        </div>
        <div
          className="service"
          id="test"
          onClick={() => {
            setOpenTest(!openTest);
          }}
        >
          <h4>{languages[language].test}</h4>
          <p id="testText">{languages[language].testText}</p>
        </div>
        <div
          className="service"
          id="hosting"
          onClick={() => {
            setOpenHosting(!openHosting);
          }}
        >
          <h4>{languages[language].hosting}</h4>
          <p id="hostingText">{languages[language].hostingText}</p>
        </div>
      </article>
      <article className="littleLine">
        <div
          className="littleService"
          onClick={() => {
            setOpenWeb(!openWeb);
          }}
        >
          <h4>{languages[language].webDevelopment}</h4>
          <div className={`littleServiceWeb ${openWeb ? "show" : "hide"}`}>
            <img
              className="serviceImage"
              src={require("../images/web.png")}
              alt="webDevelopment"
            />
            <p id="textRight">{languages[language].webText}</p>
          </div>
        </div>
        <div
          className="littleService"
          onClick={() => {
            setOpenIntegrations(!openIntegrations);
          }}
        >
          <h4>{languages[language].integrations}</h4>
          <div
            className={`littleServiceIntegrations ${
              openIntegrations ? "show" : "hide"
            }`}
          >
            <img
              className="serviceImage"
              src={require("../images/integration.png")}
              alt="integrations"
            />
            <p id="textRight">{languages[language].integrationsText}</p>
          </div>
        </div>
        <div
          className="littleService"
          onClick={() => {
            setOpenCustom(!openCustom);
          }}
        >
          <h4>{languages[language].custom}</h4>
          <div
            className={`littleServiceCustom ${openCustom ? "show" : "hide"}`}
          >
            <img
              className="serviceImage"
              src={require("../images/custom.png")}
              alt="custom"
            />
            <p id="textRight">{languages[language].customText}</p>
          </div>
        </div>
        <div
          className="littleService"
          onClick={() => {
            setOpenSupport(!openSupport);
          }}
        >
          <h4>{languages[language].support}</h4>
          <div
            className={`littleServiceSupport ${openSupport ? "show" : "hide"}`}
          >
            <img
              className="serviceImage"
              src={require("../images/support.png")}
              alt="technical support"
            />
            <p id="textRight">{languages[language].supportText}</p>
          </div>
        </div>

        <div
          className="littleService"
          onClick={() => {
            setOpenDesign(!openDesign);
          }}
        >
          <h4>{languages[language].design}</h4>
          <div
            className={`littleServiceDesign ${openDesign ? "show" : "hide"}`}
          >
            <img
              className="serviceImage"
              src={require("../images/design.png")}
              alt="design"
            />
            <p id="textRight">{languages[language].designText}</p>
          </div>
        </div>
        <div
          className="littleService"
          onClick={() => {
            setOpenSEO(!openSEO);
          }}
        >
          <h4>{languages[language].SEO}</h4>
          <div className={`littleServiceSEO ${openSEO ? "show" : "hide"}`}>
            <img
              className="serviceImage"
              src={require("../images/seoService.png")}
              alt="seo"
            />
            <p id="textRight">{languages[language].SEOText}</p>
          </div>
        </div>
        <div
          className="littleService"
          onClick={() => {
            setOpenTest(!openTest);
          }}
        >
          <h4>{languages[language].test}</h4>
          <div className={`littleServiceTest ${openTest ? "show" : "hide"}`}>
            <img
              className="serviceImage"
              src={require("../images/test.png")}
              alt="test"
            />
            <p id="textRight">{languages[language].testText}</p>
          </div>
        </div>
        <div
          className="littleService"
          onClick={() => {
            setOpenHosting(!openHosting);
          }}
        >
          <h4>{languages[language].hosting}</h4>
          <div
            className={`littleServiceHosting ${openHosting ? "show" : "hide"}`}
          >
            <img
              className="serviceImage"
              src={require("../images/hosting.png")}
              alt="hosting"
            />
            <p id="textRight">{languages[language].hostingText}</p>
          </div>
        </div>
      </article>
    </div>
  );
}
export default Services;
