import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize("G-PLVDTNVZLH");
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

SendAnalytics()