import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { LanguageContext } from "../App";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Virtual,
} from "swiper/core";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../CSS/prices.css";
import "../CSS/main.css";
import { EffectCoverflow } from "swiper";
import Card from "./Card";
import scrollPrices from "../functions/scrollPrices";
import { Helmet } from "react-helmet";

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

class Prices extends React.Component {
  render() {
    return (
      <div id="prices" onLoad={scrollPrices}>
        <Helmet>
          <title>Ekorporation webutveckling pris</title>
          <meta
            name="description"
            content="På Ekorporation tycker vi att det är viktigt för kunden att veta vad en tjänst kan kosta, och därför har vi startpriser och faktorer som påverkar dem listad."
          />
        </Helmet>
        <LanguageContext.Consumer>
          {(value) => (
            <>
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                initialSlide={1}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 8,
                  slideShadows: true,
                }}
                modules={[EffectCoverflow]}
                className="mySwiper"
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
              >
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
                <SwiperSlide>
                  <Card
                    language={value[0]}
                    serviceName="WebDevelopment"
                    description="WebDevelopment"
                    image={require("../images/web.png")}
                    price="WebDevelopment"
                    vat="WebDevelopment"
                    cardId="popupWeb"
                    className="learnWeb"
                    button="WebDevelopment"
                    priceFactors="WebDevelopment"
                    classFactor="mainFactor"
                    mainFactor="WebDevelopment"
                    factors="WebDevelopment"
                    key="0"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    language={value[0]}
                    serviceName="Integrations"
                    description="Integrations"
                    image={require("../images/integration.png")}
                    price="Integrations"
                    vat="Integrations"
                    link="learn more >"
                    cardId="popupIntegrations"
                    className="learnIntegrations"
                    button="Integrations"
                    priceFactors="Integrations"
                    factorOne="Integrations"
                    key="1"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    language={value[0]}
                    serviceName="CustomMadeSolutions"
                    description="CustomMadeSolutions"
                    image={require("../images/custom.png")}
                    price="CustomMadeSolutions"
                    vat="CustomMadeSolutions"
                    link="learn more >"
                    cardId="popupCustom"
                    className="learnCustom"
                    button="CustomMadeSolutions"
                    priceFactors="CustomMadeSolutions"
                    factors="CustomMadeSolutions"
                    key="2"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    language={value[0]}
                    serviceName="TechnicalSupport"
                    description="TechnicalSupport"
                    image={require("../images/support.png")}
                    price="TechnicalSupport"
                    vat="TechnicalSupport"
                    link="learn more >"
                    cardId="popupSupport"
                    className="learnSupport"
                    button="TechnicalSupport"
                    priceFactors="TechnicalSupport"
                    factors="TechnicalSupport"
                    key="3"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    language={value[0]}
                    serviceName="GraphicalDesign"
                    description="GraphicalDesign"
                    image={require("../images/design.png")}
                    price="GraphicalDesign"
                    vat="GraphicalDesign"
                    link="learn more >"
                    cardId="popupDesign"
                    className="learnDesign"
                    button="GraphicalDesign"
                    priceFactors="GraphicalDesign"
                    factors="GraphicalDesign"
                    key="4"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    language={value[0]}
                    serviceName="SEO"
                    description="SEO"
                    image={require("../images/seoService.png")}
                    price="SEO"
                    vat="SEO"
                    link="learn more >"
                    cardId="popupSEO"
                    className="learnSEO"
                    button="SEO"
                    priceFactors="SEO"
                    factors="SEO"
                    key="5"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    language={value[0]}
                    serviceName="Test"
                    description="Test"
                    image={require("../images/test.png")}
                    price="Test"
                    vat="Test"
                    link="learn more >"
                    cardId="popupTest"
                    className="learnTest"
                    button="Test"
                    priceFactors="Test"
                    factors="Test"
                    key="6"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    language={value[0]}
                    serviceName="Hosting"
                    description="Hosting"
                    image={require("../images/hosting.png")}
                    price="Hosting"
                    vat="Hosting"
                    link="learn more >"
                    cardId="popupHosting"
                    className="learnHosting"
                    button="Hosting"
                    priceFactors="Hosting"
                    factors="Hosting"
                    key="7"
                  />
                </SwiperSlide>
              </Swiper>
            </>
          )}
        </LanguageContext.Consumer>
      </div>
    );
  }
}
export default Prices;
