import { React, useContext } from "react";
import "../CSS/AboutUs.css";
import "../CSS/main.css";
import { LanguageContext } from "../App";
import { scrollHome } from "../functions/scrollHome";
import { Helmet } from "react-helmet";

function AboutUs() {
  const languages = {
    en: {
      about: "Let us take care of your digital presence",
      we: "Ekorporation offers comprehensive software solutions, from website design and development to tailored software applications (see examples below). Empower your business with our expertise in cutting-edge technology.",
    },
    se: {
      about: "Låt oss ta hand om er digitala närvaro",
      we: "Ekorporation erbjuder omfattande programvarulösningar, från webbdesign och -utveckling till skräddarsydda programvaruapplikationer (se exempel nedan). Stärk ert företag med vår expertis inom spetsteknologi.",
    },
  };

  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <section onLoad={scrollHome}>
      <Helmet>
        <title>Webbyrå Stockholm webbplatser och mjukvarulösningar</title>
        <meta
          name="description"
          content="Ekorporation hjälper er lösa alla era digitala behov på ett sätt som passar bäst, med högsta kvalité och maximal transparens. Vi utvecklar webbplatser, mjukvarulösningar och hjälper er att lyckas."
        />
      </Helmet>
      <div className="box">
        <img
          className="about"
          src={require("../images/about.jpg")}
          alt="about us"
        />
        <div className="aboutText" id="home">
          <h1>{languages[language].about}</h1>
          <p className="pHome">{languages[language].we}</p>
        </div>
      </div>
    </section>
  );
}
export default AboutUs;
