import { React, useContext, useState, useEffect } from "react";
import "../CSS/Menu.css";
import "../CSS/main.css";
import { LanguageContext } from "../App";
import popup from "../functions/popup";
import close from "../functions/close";
import $ from "jquery";
import { Link } from "react-router-dom";

function Menu() {
  $(window).scroll(function () {
    if ($(window).scrollTop() > 400) {
      $(".back").addClass("back-fixed");
    } else {
      $(".back").removeClass("back-fixed");
    }
  });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = () => {
      setOpen(false);
    };
    document.addEventListener("mouseup", handler);
  });
  const [openMenu, setOpenMenu] = useState(false);
  useEffect(() => {
    let handlerMenu = () => {
      setOpenMenu(false);
    };
    document.addEventListener("mouseup", handlerMenu);
  });

  const languages = {
    en: {
      home: "HOME",
      aboutUs: "ABOUT US",
      services: "SERVICES",
      prices: "PRICES",
      contact: "CONTACT",
      menu: "MENU",
      contactUs: "CONTACT US",
      email: "email/phone",
      name: "name",
      website: "your website (optional)",
      question: "message",
      submit: "submit",
    },
    se: {
      home: "HEM",
      aboutUs: "OM OSS",
      services: "TJÄNSTER",
      prices: "PRISER",
      contact: "KONTAKT",
      menu: "MENY",
      contactUs: "KONTAKTA OSS",
      email: "e-mail/telefon",
      name: "namn",
      website: "er webbplats (valfritt)",
      question: "meddelande",
      submit: "skicka in",
    },
  };
  const [language, setLanguage] = useContext(LanguageContext);
  console.log(`language`, language);
  return (
    <>
      <title>webbutveckling</title>
      <header>
        <Link to="/">
          <div className="logoTop">
            <img
              className="logo"
              src={require("../images/sq.png")}
              alt="webbutveckling"
            />
          </div>
        </Link>
        <div className="dropdown">
          <button
            onClick={() => {
              setOpen(!open);
            }}
            id="language"
            className="dropbtn"
          >
            <img
              className="language"
              src={require("../images/language.png")}
              alt="choose language"
            />
          </button>
          <div
            id="myDropdown"
            className={`dropdown-content ${open ? "show" : "hide"}`}
          >
            <button value="en" id="en" onClick={() => setLanguage("en")}>
              <img
                className="EnSe"
                src={require("../images/en.png")}
                alt="english"
              />
            </button>
            <button value="se" id="se" onClick={() => setLanguage("se")}>
              <img
                className="EnSe"
                src={require("../images/se.png")}
                alt="swedish"
              />
            </button>
          </div>
        </div>
        <div className="back">
          <div className="contactButton" onClick={popup}>
            <div className="contactHexagon">
              <div className="littleContactHexagon">
                <h2 className="contactSize">{languages[language].contactUs}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="form">
          <span className="close-button" onClick={close}>
            ×
          </span>

          <form
            action="https://formspree.io/f/myyqbpjg"
            method="post"
            className="modal-content"
          >
            <h3 className="contactUs">{languages[language].contactUs}</h3>
            <input
              className="input"
              placeholder={languages[language].email}
              required
              name="contact"
            />
            <br />
            <input
              className="input"
              placeholder={languages[language].name}
              required
              name="name"
            />
            <br />
            <input
              className="input"
              placeholder={languages[language].website}
              name="web"
            />
            <br />
            <textarea
              className="input inputQuestion"
              rows="5"
              cols="33"
              style={{ overflow: "hidden", resize: "none" }}
              placeholder={languages[language].question}
              name="question"
            />
            <br />
            <button className="submit" type="submit">
              {languages[language].submit}
            </button>
          </form>
        </div>

        <div className="menuButton">
          <div className="biggestHexagonButton">
            <div className="bigHexagonButton">
              <div className="hexagonButton">
                <button
                  onClick={() => {
                    setOpenMenu(!openMenu);
                  }}
                  className="littleHexagonButton"
                >
                  <h3>{languages[language].menu}</h3>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`menuList ${openMenu ? "showMenu" : "hide"}`}
          id="menuList"
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <div className="borderPoint">
              <button className="point">
                <h2>{languages[language].home}</h2>
              </button>
            </div>
          </Link>
          <Link to="/aboutUs" style={{ textDecoration: "none" }}>
            <div className="borderPoint">
              <button className="point">
                <h2>{languages[language].aboutUs}</h2>
              </button>
            </div>
          </Link>
          <Link to="/prices" style={{ textDecoration: "none" }}>
            <div className="borderPoint">
              <button className="point">
                <h2>{languages[language].prices}</h2>
              </button>
            </div>
          </Link>
          <Link to="/services" style={{ textDecoration: "none" }}>
            <div className="borderPoint">
              <button className="point">
                <h2>{languages[language].services}</h2>
              </button>
            </div>
          </Link>
          <Link to="/contact" style={{ textDecoration: "none" }}>
            <div className="borderPoint">
              <button className="point">
                <h2>{languages[language].contact}</h2>
              </button>
            </div>
          </Link>
        </div>
        <div className="container">
          <div className="hexagonArea first">
            <Link to="/" style={{ textDecoration: "none" }}>
              <div className="biggestHexagon">
                <div className="bigHexagon">
                  <div className="hexagon">
                    <div className="littleHexagon">
                      <h3>{languages[language].home}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/aboutUs" style={{ textDecoration: "none" }}>
              <div className="biggestHexagon">
                <div className="bigHexagon">
                  <div className="hexagon">
                    <div className="littleHexagon">
                      <h3>{languages[language].aboutUs}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <div className="hexagonArea">
              <Link to="/prices" style={{ textDecoration: "none" }}>
                <div className="biggestHexagon">
                  <div className="bigHexagon">
                    <div className="hexagon">
                      <div className="littleHexagon">
                        <h3>{languages[language].prices}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="hexagonArea last">
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <div className="biggestHexagon">
                <div className="bigHexagon">
                  <div className="hexagon">
                    <div className="littleHexagon">
                      <h3>{languages[language].contact}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/services" style={{ textDecoration: "none" }}>
              <div className="biggestHexagon">
                <div className="bigHexagon">
                  <div className="hexagon">
                    <div className="littleHexagon">
                      <h3>{languages[language].services}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </header>
      <div className="cloud">
        <div className="cloudcontent">
          <img
            className="cloud1"
            alt="cloud"
            src={require("../images/cloud-02.png")}
          />
          <img
            className="cloud2"
            alt="cloud"
            src={require("../images/cloud-01.png")}
          />
          <img
            className="cloud3"
            alt="cloud"
            src={require("../images/cloud-02.png")}
          />
          <img
            className="cloud4"
            alt="cloud"
            src={require("../images/cloud-02.png")}
          />
        </div>
      </div>
    </>
  );
}
export default Menu;
