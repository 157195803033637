import { React, useContext } from "react";
import "../CSS/main.css";
import "../CSS/footer.css";
import { LanguageContext } from "../App";
import Grid from "@mui/material/Grid";
import popup from "../functions/popup";

function Footer() {
  const languages = {
    en: {
      submit: "Send us a question",
      tel: "+46739765954",
    },
    se: {
      submit: "Skicka in er fråga",
      tel: "0739765954",
    },
  };

  const [language, setLanguage] = useContext(LanguageContext);

  return (
    <footer id="section-1">
      <Grid container className="footerContainer">
        <Grid item xs={12} sm={4}>
          <h4
            style={{ textAlign: "center", marginRight: "5%", fontSize: "20px" }}
          >
            Ekorporation AB: Software Solutions
          </h4>
        </Grid>
        <Grid item xs={6} sm={3} id="borderLeft">
          <a href="mailto: mikael@ekorporation.se">
            <img
              className="footerPic"
              src={require("../images/mail.png")}
              alt="mail"
            />
            <h5> mikael@ekorporation.se</h5>
          </a>
        </Grid>
        <Grid item xs={6} sm={2} className="borderLeft">
          <a href="tel:+46739765954">
            <img
              className="footerPic"
              src={require("../images/phone.png")}
              alt="phone"
            />{" "}
            <h5>{languages[language].tel}</h5>
          </a>
        </Grid>
        <Grid item xs={12} sm={2} id="borderLeft">
          <div className="submitHexagon" onClick={popup}>
            <div className="littleSubmitHexagon">
              <h5>
                <b>{languages[language].submit}</b>
              </h5>
            </div>
          </div>
        </Grid>
      </Grid>
    </footer>
  );
}
export default Footer;
