import React from "react";
import "../CSS/prices.css";
import "../CSS/main.css";
export default class Card extends React.Component {
  render() {
    let prices = {
      en: {
        WebDevelopment: {
          name: "Web Development",
          description: [
            "◈ Creation of a perfect website of any complexity for your specific needs",
            "◈ Improving the design, user experience, or adding functionality to your website",
            "◈ Mobile and user friendly",
            "◈ Multi-lingual websites",
            "◈ Coding from scratch or using cms, depending on your needs",
          ],
          price: "from 980$",
          vat: "(incl. VAT)",
          button: "LEARN MORE >",
          priceFactors: "Price Factors:",
          mainFactor: "The main factor is complexity:",
          factors: [
            "Landing page (1 page websites)",
            "Simple website with no complex logic up to 5 pages",
            "Medium website several pages up to 10 pages with some functionality",
            "Complex websites with any extra functionality you need, examples include: larger ecommerce websites, backoffices, search engines etc.",
          ],
        },
        Integrations: {
          name: "Integrations",
          description: [
            "◈ Adding a third-party functionality to your website",
            "◈ Any complexity",
            "◈ Examples: payment, inventory management, booking systems etc",
            "◈ Multi-lingual websites",
            "◈ Coding from scratch or using cms, depending on your needs",
          ],
          price: "from 300$",
          vat: "(incl. VAT)",
          button: "LEARN MORE >",
          priceFactors: "Price Factors:",
          factors: [
            "Some integrations are more demanding than others due to their security, performance and contract requirements",
          ],
        },
        CustomMadeSolutions: {
          name: "Custom Made Solutions",
          description: [
            "◈ Everything is possible",
            "◈ We will bring your idea to life",
            "◈ All the functionality you need in one place",
            "◈ Automation for your manual processes",
          ],
          price: "from 720$",
          vat: "(incl. VAT)",
          button: "LEARN MORE >",
          priceFactors: "Price Factors:",
          factors: ["Project size and complexity"],
        },

        TechnicalSupport: {
          name: "Technical Support",
          description: [
            "◈ 24/7 support",
            "◈ Content management for your website",
            "◈ Technical advise for both hardware and software based on a thorough evaluation of options",
            "◈ Handling of your technical needs",
            "◈ Updating your systems",
            "◈ Monitoring your systems and taking care of incidents",
          ],
          price: "from 55$",
          vat: "(incl. VAT)",
          button: "LEARN MORE >",
          priceFactors: "Price Factors:",
          factors: ["Based on the task selection"],
        },
        GraphicalDesign: {
          name: "GraphicalDesign",
          description: [
            "◈ Mobile friendly website design",
            "◈ Custom graphical images",
            "◈ Logo design",
            "◈ Photos",
          ],
          price: "from 33$",
          vat: "(incl. VAT)",
          button: "LEARN MORE >",
          priceFactors: "Price Factors",
          factors: [
            "For website design: size of the website",
            "For everything else: quantity of the required items",
          ],
        },
        SEO: {
          name: "SEO",
          description: [
            "◈ On-page: Keywords, content and UX optimization",
            "◈ Off-page: Backlinks, internal linking, reputation",
            "◈ Technical: Performance and security optimizations",
            "◈ Google Business profile optimization",
            "◈ Search Engine Metrics ",
          ],
          price: "from 150$",
          vat: "(incl. VAT)",
          button: "LEARN MORE >",
          priceFactors: "Price Factors:",
          factors: [
            "For on/off page: target goals for search engine rankings",
            "For technical: size of the website",
          ],
        },
        Test: {
          name: "Test & Bugfix",
          description: [
            "◈ Full User testing",
            "◈ Performance and load testing",
            "◈ Comprehensive report on the findings",
            "◈ Fixing existing issues on your website",
            "◈ Minor adjustments to your website",
          ],
          price: "from 65$",
          vat: "(incl. VAT)",
          button: "LEARN MORE >",
          priceFactors: "Price Factors:",
          factors: [
            "For testing: size of the website",
            "For bugfix: number of issues",
          ],
        },
        Hosting: {
          name: "Hosting & Infra",
          description: [
            "◈ Web hosting with SSL certificates (24/7 support included)",
            "◈ Domain name selection",
            "◈ Infrastructure to support the backend functionality",
            "◈ Database maintenance",
          ],
          price: "from 48$/year",
          vat: "(incl. VAT)",
          button: "LEARN MORE >",
          priceFactors: "Price Factors:",
          factors: [
            "For hosting: size of the website",
            "For domain name: depending on the name availability",
            "Infracture and Database: based on the performance needs",
          ],
        },
      },
      se: {
        WebDevelopment: {
          name: "Webbutveckling",
          description: [
            "◈ En perfekt webbplats med valfri komplexitet för era specifika behov",
            "◈ Förbättring av design, användarupplevelse eller tillägg av funktionalitet till er webbplats",
            "◈ Mobil-och användarvänlig",
            "◈ Flerspråkiga webbplatser",
            "◈ Kodning från grunden eller användning av CMS, beroende på era behov",
          ],
          price: "från 9800:-",
          vat: "(inkl. moms)",
          button: "LÄS MER >",
          priceFactors: "Prisfaktorer:",
          mainFactor: "Huvudfaktorn är komplexiteten:",
          factors: [
            "Landing page (1-sidiga webbplatser)",
            "Enkla webbplatser utan komplex logik upp till 5 sidor",
            "Medelstora webbplatser med upp till 10 sidor med viss funktionalitet",
            "Komplexa webbplatser med all extra funktionalitet ni behöver, exempel inkluderar: större e-handelswebbplatser, backoffices, sökmotorer osv",
          ],
        },
        Integrations: {
          name: "Integrationer",
          description: [
            "◈ Tillägg av tredjepartsfunktionalitet till er webbplats",
            "◈ Valfri komplexitet",
            "◈ Exempel: betalning, lagerhantering, bokningssystem osv",
          ],
          price: "från 3000:-",
          vat: "(inkl. moms)",
          button: "LÄS MER >",
          priceFactors: "Prisfaktorer:",
          factors: [
            "Vissa integrationer är mer krävande än andra på grund av deras säkerhet, prestanda och kontraktskrav",
          ],
        },
        CustomMadeSolutions: {
          name: "Skräddarsydda lösningar",
          description: [
            "◈ Allt är möjligt!",
            "◈ Vi kommer att förverkliga er idé",
            "◈ All funktionalitet ni behöver på ett ställe",
            "◈ Automatisering för era manuella processer",
          ],
          price: "från 7200:-",
          vat: "(inkl. moms)",
          button: "LÄS MER >",
          priceFactors: "Prisfaktorer:",
          factors: ["Projektets storlek och komplexitet"],
        },
        TechnicalSupport: {
          name: "Teknisk support",
          description: [
            "◈ 24/7 support",
            "◈ Innehållshantering för er webbplats",
            "◈ Teknisk rådgivning för både hårdvara och programvara baserat på en noggrann utvärdering av alternativ",
            "◈ Hantering av era tekniska behov",
            "◈ Uppdatering av era system",
            "◈ Övervakning av era system och incidentshantering",
          ],
          price: "från 550:-",
          vat: "(inkl. moms)",
          button: "LÄS MER >",
          priceFactors: "Prisfaktorer:",
          factors: ["Baserat på uppgiftsval"],
        },
        GraphicalDesign: {
          name: "Grafisk design",
          description: [
            "◈ Mobilvänlig webbplatsdesign",
            "◈ Anpassade grafiska bilder",
            "◈ Logodesign",
            "◈ Foton",
          ],
          price: "från 330:-",
          vat: "(inkl. moms)",
          button: "LÄS MER >",
          priceFactors: "Prisfaktorer:",
          factors: [
            "För webbplatsdesign: webbplatsens storlek",
            "För allt annat: mängd av de önskade objekten",
          ],
        },
        SEO: {
          name: "SEO",
          description: [
            "◈ On-page: Nyckelord, innehåll och UX-optimering.",
            "◈ Off-page: Backlinks, intern länkning, rykte.",
            "◈ Teknisk: Prestanda- och säkerhetsoptimiseringar.",
            "◈ Optimering av Google Business-profilen.",
            "◈ Sökmotorprestanda.",
          ],
          price: "från 1500:-",
          vat: "(inkl. moms)",
          button: "LÄS MER >",
          priceFactors: "Prisfaktorer:",
          factors: [
            "För on/off-page: mål för sökmotorrankningar",
            "För teknisk: webbplatsens storlek",
          ],
        },
        Test: {
          name: "Test & Bugfix",
          description: [
            "◈ Full användartestning",
            "◈ Prestanda- och belastningstestning",
            "◈ Utförlig rapport om resultaten",
            "◈ Fixa befintliga problem på er webbplats",
            "◈ Mindre justeringar av er webbplats",
          ],
          price: "från 650:-",
          vat: "(inkl. moms)",
          button: "LÄS MER >",
          priceFactors: "Prisfaktorer:",
          factors: [
            "För testning: webbplatsens storlek",
            "För bugfix: antal problem",
          ],
        },
        Hosting: {
          name: "Webbhotell & Infrastruktur",
          description: [
            "◈ Webbhotell med SSL-certifikat (24/7 support ingår)",
            "◈ Val av domännamn",
            "◈ Infrastruktur för att stödja backend-funktionalitet",
            "◈ Underhåll av databasen",
          ],
          price: "från 480:-/år",
          vat: "(inkl. moms)",
          button: "LÄS MER >",
          priceFactors: "Prisfaktorer:",
          factors: [
            "För webbhotell: webbplatsens storlek",
            "För domännamn: beroende på tillgängliga webbadresser",
            "För infrastruktur och databas: baserat på prestandabehoven",
          ],
        },
      },
    };

    function description(description) {
      let paragraphs = [];
      for (let i = 0; i < description.length; i++) {
        let newParagraph = <li>{description[i]}</li>;
        paragraphs.push(newParagraph);
      }
      return paragraphs;
    }
    function factor(factors) {
      let paragraphs = [];
      for (let i = 0; i < factors.length; i++) {
        let newParagraph = <li>{factors[i]}<br/></li>;
        paragraphs.push(newParagraph);
      }
      return paragraphs;
    }
    return (
      <>
        <div>
          <img
            className="imgPrices"
            style={{ width: "30%" }}
            src={this.props.image}
            alt="software solutions"
          />
          <h4
            style={{
              textAlign: "center",
            }}
          >
            {prices[this.props.language][this.props.serviceName]?.["name"]}
          </h4>
          <h3 className="description">
            {description(
              prices[this.props.language][this.props.serviceName]?.[
                "description"
              ]
            )}
          </h3>
          <h4 className="centerPrice">
            {" "}
            {prices[this.props.language][this.props.price]?.["price"]}{" "}
          </h4>
          <h4 className="centerVat">
            {" "}
            {prices[this.props.language][this.props.vat]?.["vat"]}{" "}
          </h4>
        </div>
        <button className={this.props.className}>
          {" "}
          {prices[this.props.language][this.props.button]?.["button"]}
        </button>
        <div className={this.props.cardId}>
          <h4 style={{ marginTop: "3%" }}>
            {" "}
            {
              prices[this.props.language][this.props.priceFactors]?.[
                "priceFactors"
              ]
            }
          </h4>
          <h4 className={this.props.classFactor}>
            {" "}
            {prices[this.props.language][this.props.mainFactor]?.["mainFactor"]}
          </h4>
          <p className="factors">
            {factor(
              prices[this.props.language][this.props.serviceName]?.["factors"]
            )}
          </p>
        </div>
      </>
    );
  }
}
