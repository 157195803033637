import { React, useContext } from "react";
import "../CSS/information.css";
import "../CSS/main.css";
import { LanguageContext } from "../App";
import Grid from "@mui/material/Grid";

function Information() {
  const languages = {
    en: {
      infoFirst:
        "We understand that navigating the digital landscape can be overwhelming, with new concepts and services bombarding businesses from all sides. That is why we prioritize your needs, guiding you to the best-fit solutions tailored to your specific requirements. If you are not keen on diving deep into the details, that is fine too. We will create the best solution possible, allowing you to focus on other aspects of your business. With us, you will experience personalized care and expertise that empowers your business for success, ensuring both a minimum price and maximum transparency.",
      infoSecond:
        "At Ekorporation, we take pride in our broad expertise in both front-end and back-end software development, and we thrive on challenges. We firmly believe that nothing is impossible, and we are passionate about solving complex problems. Bring us any idea or functionality you need, and we will do our best to bring it to life. Our comprehensive solutions encompass various aspects, from inventory management and booking systems to payment and other third-party integrations, as well as automation of your daily routines. By entrusting us with your digital needs, you can focus on the more critical aspects of your business while we handle the technical complexities.",
      infoThird:
        "Technical requirements do not have to be boring! We believe in fostering a collaborative environment. Whether it is over a cup of coffee in a meeting room or during a walk, we will discuss your requirements in a way that suits you best. We understand that projects can evolve, and we do not hard-lock the requirements. If you think of something after the work has started, we will always strive to incorporate your new ideas into the project. Transparency is essential to us, so we are more than happy to provide a series of demos throughout the development process. With us, you are  not just a client; you are a valued partner, and your success is our utmost priority.",
      infoFourth:
        "At Ekorporation, we thrive on working with diverse platforms to meet our clients' unique needs. With a passion for coding, we deliver perfect solutions tailored to your specific requirements. Our websites are primarily built with React, providing a dynamic user experience. However, we are highly flexible and can also create CMS-based websites using WordPress, Drupal, or Shopify, depending on what best suits your project. For backend development, we rely on the robust capabilities of Java. Moreover, our team excels in databases, Amazon Web Services, AI-powered technologies, SEO, and security, ensuring that your project receives the highest level of quality possible.",
    },
    se: {
      infoFirst:
        "Vi förstår att navigera i den digitala världen kan vara överväldigande, med nya begrepp och tjänster som bombarderar företag från alla håll. Därför prioriterar vi era behov och guidar er till de bästa lösningarna anpassade efter just era specifika krav. Om ni inte är intresserad av att gräva djupt i detaljerna är det också okej, vi tar hand om allt. Vi skapar den bästa möjliga lösningen för era krav, så att ni kan fokusera på andra aspekter av ert företag. Med oss kommer ni att uppleva personlig service och expertis som stärker ert företag för framgång, till ett minimipris och med maximal transparens.",
      infoSecond:
        "På Ekorporation är vi stolta över vår breda kompetens inom både front-end och back-end mjukvaruutveckling, och vi älskar nya utmaningar. Vi tror starkt på att ingenting är omöjligt och brinner för att lösa komplexa problem. Ta med er er idé eller den funktionalitet ni behöver, oavsett dess komplexitet, så gör vi vårt bästa för att förverkliga den. Våra programvarulösningar tar hand om allt ni bevöver för ert företag: från lagerhantering och bokningssystem till betalnings- och andra tredjepartsintegrationer. Vi kan även automatisera era dagliga rutiner om ni så önksar. Genom att anförtro oss era digitala behov kan ni fokusera på de viktigare aspekterna av er företag, medan vi hanterar de tekniska aspekterna.",
      infoThird:
        "Tekniska krav behöver inte vara tråkiga! Vi är säkra att vi kan bygga en samarbetsvänlig miljö. Oavsett om det är över en kopp kaffe i ett mötesrum eller under en promenad, kommer vi att diskutera era krav på ett sätt som passar er bäst. Vi förstår att en idé kan utvecklas vidare och låser inte fast kraven. Om ni kommer på något efter att arbetet har påbörjats, strävar vi alltid efter att införliva era nya idéer i projektet. Transparens är viktigt för oss, så vi är mer än glada att erbjuda en serie av demo sessioner under utvecklingsprocessen. Med oss är ni inte bara en kund; ni är en uppskattad partner, och er framgång är vår högsta prioritet.",
      infoFourth:
        "På Ekorporation tycker vi om att arbeta med olika plattformar för att möta våra kundens unika behov. Med en passion för programmering levererar vi perfekta lösningar skräddarsydda efter era specifika krav. Våra webbplatser byggs främst med React, vilket ger en dynamisk användarupplevelse. Dock är vi mycket flexibla och kan också skapa CMS-baserade webbplatser med WordPress, Drupal eller Shopify, beroende på vad som passar er projekt bäst. För backend-utveckling förlitar vi oss på Javas robusta kapaciteter. Dessutom är vi kunniga inom databaser, Amazon Web Services, AI-drivna teknologier, SEO och säkerhet, vilket säkerställer att er projekt får högsta kvalitet.",
    },
  };

  const [language, setLanguage] = useContext(LanguageContext);

  return (
    <main>
      <section className="big">
        <Grid container>
          <Grid item xs={7}>
            <div className="infoBigLeft">
              <article className="size">{languages[language].infoFirst}</article>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className="infoImage Right">
              <img
                className="littleInfoImage Right"
                src={require("../images/infoFirst.png")}
                alt="information"
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className="infoImage Left">
              <img
                className="littleInfoImage Left"
                src={require("../images/infoSecond.png")}
                alt="information"
              />
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className="infoBigRight">
              <article style={{ textAlign: "right" }} className="size">
                {languages[language].infoSecond}
              </article>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className="infoBigLeft">
              <article className="size">{languages[language].infoThird}</article>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className="infoImage Right">
              <img
                className="littleInfoImage Right"
                src={require("../images/infoThird.png")}
                alt="information"
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className="infoImage Left">
              <img
                className="littleInfoImage Left"
                src={require("../images/infoFourth.png")}
                alt="information"
              />
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className="infoBigRight">
              <article style={{ textAlign: "right" }} className="size">
                {languages[language].infoFourth}
              </article>
            </div>
          </Grid>
        </Grid>
      </section>
      <section className="little">
        <Grid container>
          <Grid item xs={10}>
            <article className="infoLittle infoLeft">{languages[language].infoFirst}</article>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <article className="infoLittle infoRight">
              {languages[language].infoSecond}
            </article>
          </Grid>
          <Grid item xs={10}>
            <article className="infoLittle infoLeft">{languages[language].infoThird}</article>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <article className="infoLittle infoRight">
              {languages[language].infoFourth}
            </article>
          </Grid>
        </Grid>
      </section>
    </main>
  );
}
export default Information;
